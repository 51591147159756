.text-slider-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust as needed */
  margin: auto;
  border-left: 5px solid rgba(122, 47, 15, 1);
  justify-content: center;
  align-items: center;
  display: flex;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
}

.text-slider {
  display: flex;
  overflow: hidden;
  height: 100px; /* Adjust the height of the slide */
  width: 400px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.slidetext {
  font-size: 20px;
  display: flex;
  top: 0;
  right: 0;
  width: 100%;
  padding: auto 0;
  position: absolute;
  align-items: center;
  color: black;
  bottom: 0;
  left: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  padding-left: 10px;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.slidetext.active {
  opacity: 1;
  transform: translateY(0%);
}
