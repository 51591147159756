@font-face {
  font-family: "belleza";
  src: url("../../assets/font/Belleza.ttf") format("ttf");
}

.active {
  color: rgba(122, 47, 15, 1);
}

.link:hover {
  color: rgba(122, 47, 15, 1);
}

.links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  a {
    font-size: medium;
    color: white;
    text-decoration: none;
  }
}

.menu-overlay1 {
  .links {
    font-family: "belleza";
    width: 100%;
    height: 100%;
    display: flex;
    // padding-left: 10%;
    position: relative;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media only screen and (max-width: 1350px) {
      flex-direction: column;
    }
    position: relative;

    .link {
      font-size: 1.3rem;
      margin: 20px;
      padding: 0 20px;
      @media only screen and (max-width: 1350px) {
        padding: 0;
      }
      text-decoration: none;
      color: white;
    }

    .get-quote-border {
      position: absolute;
      right: 20px;
      justify-content: flex-end;
      margin-right: 0;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      height: 3rem;

      a {
        color: rgba(122, 47, 15, 1);
      }
    }
  }
}

.menu-overlay2 {
  .links {
    display: flex;
    flex-direction: column;
  }

  .link {
    font-size: 1.5rem;
    margin: 20px;
    padding: 0 20px;
    @media only screen and (max-width: 1350px) {
      text-align: center;
      padding: 10px 0;
      margin: 10px 0;
      color: white;
      background-color: rgba(122, 47, 15, 1);
      width: 100%;
    }
  }
}
