.welcomesection-outer-div {
  padding: 10px;
  width: 100%;
}

.welcomesection {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.welcomeImage {
  width: 100%;
  height: 400px;
  @media only screen and (max-width: 768px) {
    height: 300px;
    width: 80%;
  }
}

.welcomesectiontext {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin: 20px 0;
  }
}

.welcomesectionimage {
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    margin: 10px 10px;
  }
}

.slidingtext {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-left: 5px solid rgba(122, 47, 15, 1);

  p {
    font-size: 15px;
  }
}
