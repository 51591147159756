@font-face {
  font-family: "aleo";
  src: url("../../assets/font/aleo-bold.ttf") format("ttf");
}

@font-face {
  font-family: "montserrat";
  src: url("../../assets/font/Montserrat-Light.ttf") format("ttf");
}

.contact-page {
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.contact_map_section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.text-zone {
  width: 50%;
}

.map-wrap {
  width: 50%;
  // padding-right: 10%;
  .map {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 0;
  }
}

.contact-info {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0;
  justify-content: space-evenly;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  p {
    font-family: "montserrat";
    margin: 0;
  }
}

.phone-section,
.email-section,
.address-section {
  width: 33%;
  display: flex;
  padding: 10px;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.phone_img,
.location_image_icon,
.email_image_icon {
  width: 20px;
  height: 20px;
}

.phone_inner,
.email-inner,
.address-inner {
  display: flex;
  flex-direction: row;
  // .phone_icon {
  //   width: 30px;
  // }
}

.phone_image2,
.email_image2,
.location_image_icon {
  margin-right: 10px;
}

.phone-title,
.email-title,
.address-title {
  font-family: "aleo";
}
