.header {
  background-color: white;
  height: 14vh;

  // .classnamediv {
  //   @media (max-width: 1000px) {
  //     width: 50%;
  //   }
  //   width: 30%;
  //   height: 100%;
  // }

  .logo-div {
    display: flex;
    height: 14vh;
    flex-direction: column;
    align-items: start;
    width: 20%;

    @media (max-width: 1000px) {
      height: 100px;
      width: 30%;
    }
    // width: 25vw;
    .logo-text-div {
      width: 100%;
      height: 40%;
      text-align: start;
      display: flex;
      justify-content: end;
    }
  }

  .contact_section {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    color: rgba(122, 47, 15, 1);
    height: 14vh;

    // @media (max-width: 768px) {
    //   flex-direction: column;
    //   font-size: 15px;
    // }

    @media (max-width: 1000px) {
      width: 70%;
      flex-direction: column;
      justify-content: space-around;
      font-size: 3vw;
      height: 14vh;
    }
  }

  .cell_section,
  .email_section {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 10px;
    @media (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  p {
    text-align: center;
    margin: 0;
  }

  a {
    color: rgba(122, 47, 15, 1);
    text-decoration: none;
  }

  .phone,
  .email_icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;

    @media (max-width: 768px) {
      width: 2rem;
      height: 2rem;
    }
  }
}

.logo {
  margin-left: 10px;
  height: 80%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    height: 8vh;
    width: 100%;
  }
}
