.footer {
  background-color: rgba(122, 47, 15, 1);
}

.footer_inside {
  color: white;
  margin: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  // position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.about_footer,
.quicklink_footer,
.reach_us_footer {
  width: 33%;
  padding: 10px;
  .about_description p {
    text-align: left;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.quicklink_footer {
  position: relative;
}

.links-container {
  position: relative;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
}

.links-container::before,
.links-container::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute; /* Position the borders */
  top: 0;
  bottom: 20%;
  /* Border style */

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.links-container::before {
  left: 0;
  width: 10%; /* Adjust the width of the border */
  border-left: 5px solid white;
}

.links-container::after {
  right: 0;
  width: 10%; /* Adjust the width of the border */
  border-right: 5px solid white;
}
.home_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
// .quicklink_footer {
//   border-left: 2px solid white;
//   border-right: 2px solid white;
// }

.about_title_container,
.quicklink_title_container,
.reach_us_title_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links-container {
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
  }

  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  a {
    color: white;
    margin: 10px 0;
  }

  a:hover {
    color: white;
  }

  .get-quote-border {
    margin: 10px 0;
  }
}

.reach_container {
  display: flex;
  flex-direction: row;

  .phone_image,
  .email_image,
  .map_image {
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
}

.map_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email_container,
.phone_container,
.address_container {
  display: flex;
  flex-direction: row;

  .address p {
    text-align: left;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.phone p {
  white-space: nowrap;
  margin: 0;
}
