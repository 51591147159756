@font-face {
  font-family: helveticaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\helvetica.ttf");
}

@font-face {
  font-family: bellezaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\Belleza.ttf");
}

.aboutimages,
.packingDiv {
  display: flex;
  flex-direction: column;

  .topimage {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .title {
    font-family: bellezaFont;
  }

  .vtitle {
    padding: 5px;
    p {
      align-items: center;
      font-size: 30px;
      font-family: bellezaFont;
      font-weight: lighter;
    }
    @media only screen and (max-width: 768px) {
      p {
        align-items: center;
        font-size: 25px;
        font-family: bellezaFont;
        font-weight: lighter;
      } 
    }
  }
  .maincontent {
    width: 60%;
    display: flex;
    align-items: center;
    align-self: center;
    p {
      padding: 10px;
      align-items: center;
      font-size: 19px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
  }
  .ourproduct {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 70px;
    @media only screen and (max-width: 768px) {
      width: 100px;
      height: 70px;
    }
  }
  .plong-brown-line {
    width: 100%;
  }
  .plineimage {
    width: 170px;
    height: 20px;
    display: flex;
    margin: auto;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .productionunit {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 110px;
    height: 70px;
    @media only screen and (max-width: 768px) {
      width: 150px;
      height: 70px;
    }
  }
  .bblock1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    margin: 10px;
    height: 100%;
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
  .pleft {
    width: 30%;
    background-color: #e7e2d9;
    border-radius: 40px;
    margin: 20px;
    padding: 15px;
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding: 10px;
      align-items: center;
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
      margin: auto 0;
      margin-left: 10px;
      margin-bottom: 10px;
      p {
        font-size: 15px;
      }
    }
  }
  .pright {
    width: 30%;
    background-color: #e7e2d9;
    border-radius: 40px;
    margin: 20px;
    padding: 15px;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding: 10px;
      align-items: center;
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
      margin: auto 0;
      margin-left: 10px;
      p {
        font-size: 15px;
      }
    }
  }
  .blockcenter {
    width: 100%;
    display: flex;
  }
  .pcenter {
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: #e7e2d9;
    border-radius: 40px;
    margin: auto;
    padding: 15px;
    align-items: center;
    p {
      padding: 10px;
      align-items: center;
      font-size: 18px;
    }
    @media only screen and (max-width: 768px) {
      width: 90%;
      margin: auto;

      p {
        font-size: 15px;
      }
    }
  }
  .ptitle {
      font-family: bellezaFont;
      align-items: center;
      font-size: 23px;
      font-family: bellezaFont;
    @media only screen and (max-width: 768px) {
      p {
        align-items: center;
        font-size: 30px;
        font-family: bellezaFont;
        margin-left: 25px;
      }
    }
  }
}

p {
  font-family: helveticaFont;
}
