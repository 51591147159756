@font-face {
  font-family: helveticaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\helvetica.ttf");
}

@font-face {
  font-family: bellezaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\Belleza.ttf");
}

.packingDiv {
  .title {
    font-family: bellezaFont;
  }
  .packagesubdiv {
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 80%;
    }

    .subtitle {
      font-family: bellezaFont;
    }
    .package-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .description {
        padding: 0 5%;
        font-size: large;
        font-family: helveticaFont;
      }

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
      }
      .image {
        width: 50%;
        height: 100%;

        @media only screen and (max-width: 1000px) {
          width: 100%;
        }

        img {
          max-width: 400px;
          max-height: 300px;

          @media only screen and (max-width: 600px) {
            max-width: 100%;
            max-height: 300px;
          }
        }
      }
    }
  }
}
