@font-face {
  font-family: bellezaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\Belleza.ttf");
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: rgba(122, 47, 15, 1);
  z-index: 2;
  margin: 0;
}
.logo-div {
  // width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 50%;
  }
}
// .logo {
//   margin-left: 20px;
//   width: 80%;
//   height: 70px;
//   margin-top: 10px;
//   @media only screen and (max-width: 768px) {
//     height: 80px;
//     width: 100%;
//   }
//   // margin-left: 50px;
// }

.hamburger-img2 {
  width: 60px;
  height: 100px;
}

.hamburger-img {
  width: 40px;
  height: 30px;
  margin: 20px 0;
}
.hamburger {
  padding: 0 15px; // 0 15px;
  background: none;
  border: none;
  // padding: 0px 15px;
  margin-right: 20px;
  @media only screen and (min-width: 1350px) {
    display: none;
  }
}

.menu-overlay1 {
  // font-family: bellezaFont;
  display: flex;
  // justify-content: start;
  // justify-self: start;
  text-align: center;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 1350px) {
    display: none;
  }
}

.menu-overlay2 {
  // font-family: bellezaFont;
  position: fixed;
  top: 0;
  z-index: 2;
  left: 0;
  width: 100%;
  background-color: white;
  height: 100%;

  .logo {
    margin-left: 0;
    width: 60%;
  }

  @media only screen and (min-width: 1350px) {
    display: none;
  }

  .menuimagerow {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }

  .links a:hover {
    color: white;
  }
}
