.image {
  // width: 300px; /* Set the desired width */
  // height: 200px; /* Set the desired height */
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
