/* 
=========================
Fonts
=========================

font-family: 'Outfit', sans-serif;

*/

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

/* 
========================
Variables
========================
*/

:root {
  font-size: 15px;

  /* Primary */
  --var-soft-blue: hsl(215, 51%, 70%);
  --var-cyan: hsl(178, 100%, 50%);
  /* Neutral */
  --var-main-darkest: hsl(217, 54%, 11%);
  --var-card-dark: hsl(216, 50%, 16%);
  --var-line-dark: hsl(215, 32%, 27%);
  // --var-lightest: white;

  /* Fonts */

  --var-heading: normal normal 600 1.5em/1.6em "Outfit", sans-serif;

  --var-small-heading: normal normal 400 1em/1em "Outfit", sans-serif;

  --var-para: normal normal 300 1em/1.55em "Outfit", sans-serif;
}

/* 
=======================
Setup
=======================
*/

// html {
//   box-sizing: border-box;
// }

.product-card,
.product-card::before,
.product-card::after {
  box-sizing: inherit;
  margin: 0;
}

// body {
//   background-color: var(--var-main-darkest);
// }
.product-card {
  // img {
  //   width: 100%;
  //   border-radius: 15px;
  //   display: block;
  // }

  a {
    color: inherit;
  }

  /* 

Eye view

https://i.postimg.cc/9MtT4GZY/view.png' border='0' alt='view */

  /*
=========================
Font Styling
=========================
*/

  .main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font: var(--var-heading);
      color: var(--var-lightest);
      // padding: 1.2em 0;
      font-size: 1.25rem;
      text-align: center;
      margin-bottom: 0;
    }

    h2 {
      font: var(--var-small-heading);
      color: var(--var-lightest);
      margin: 10px 0;
      font-weight: bold;
      text-align: center;
      /* padding on .coin-base */
    }

    p {
      font: var(--var-para);
      color: black;
    }

    span {
      color: white;
    }
  }
  /* 
=====================
Classes
=====================
*/

  /* LAYOUT */

  .card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin: 2em auto;
    background-color: #e6e6e6;
    border-radius: 15px;
    margin-bottom: 1rem;
    padding: 2.5rem;
    height: 450px;
  }

  div.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.coin-base,
  .time-left,
  .card-attribute {
    display: flex;
    align-items: center;
    // padding: 1em 0;

    .rate-button {
      background-color: white;
      color: rgba(122, 47, 15, 1);
      font-weight: bold;
      font-size: 1.2rem;
      border-radius: 2rem;
      padding: 10px;
      margin-top: 2px;
      border-color: white;
      border-style: solid;
    }
  }

  .card-attribute {
    padding-bottom: 1.5em;
    border-top: 2px solid var(--var-line-dark);
  }

  a.hero-image-container {
    position: relative;
    display: block;
  }

  /* Details */

  // img.eye {
  //   position: absolute;
  //   width: 100%;
  //   max-width: 2em;
  //   top: 44%;
  //   left: 43%;
  // }

  // @media (min-width: 400px) {
  //   img.eye {
  //     max-width: 3em;
  //   }
  // }

  .hero-image-container::after {
    content: "";
    background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 5rem;
    background-color: hsla(178, 100%, 50%, 0.3);
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  .hero-image-container:hover::after {
    opacity: 1;
  }

  .small-image {
    width: 1.2em;
    margin-right: 0.5em;
  }

  .small-avatar {
    width: 2em;
    border-radius: 200px;
    outline: 2px solid white;
    margin-right: 1.4em;
  }

  div.attribution {
    margin: 0 auto;
    width: 100%;
    font: var(--var-para);
    text-align: center;
    padding: 1.5em 0 4em 0;
    color: var(--var-line-dark);
  }
  .attribution a {
    color: var(--var-soft-blue);
  }

  @media (min-width: 600px) {
    body {
      font-size: 18px;
    }
  }

  //carousel

  .carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 400px;
  }

  .slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
  }

  .slide-hidden {
    display: none;
  }

  .arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    width: 2rem;
    height: 2rem;
    color: white;
  }

  .arrow:hover {
    cursor: pointer;
  }

  .arrow-left {
    left: -2.25rem;
  }

  .arrow-right {
    right: -2.25rem;
  }

  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
  }

  .indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
  }

  .indicator-inactive {
    background-color: grey;
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modalbox {
  position: absolute;
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 500px;
  max-height: 500px;

  @media (max-width: 800px) {
    max-width: 400px;
    max-height: 400px;
  }
}

.close-button {
  position: relative;
  top: 40px;
  z-index: 2;
  left: 90%;
  color: black;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s;
}

.close-button:hover,
.close-button:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
