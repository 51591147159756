@font-face {
  font-family: bellezaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\Belleza.ttf");
}

.contact {
  padding: 10px;
}

.contact-inner-div {
  width: 100%;
  // margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactHeading {
  span {
    font-family: bellezaFont;
    font-size: 2rem;
  }
}

// .headingLineDiv {
//   height: 100px;
// }

.underLine {
  height: 50px;
  width: 100%;
}

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    .first-li,
    .second-li,
    .third-li {
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        // opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      .half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="number"] {
    width: 100%;
    border: 0;
    background: #e6e6e6;
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #e6e6e6;
    height: 50px;
    font-size: 16px;
    color: black;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: rgba(122, 47, 15, 1);
    background: 0 0;
    font: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid rgba(122, 47, 15, 1);
    float: right;
    border-radius: 4px;

    &:hover {
      background: rgba(122, 47, 15, 1);
      color: white;
    }
  }
}
