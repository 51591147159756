@font-face {
  font-family: bellezaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\Belleza.ttf");
}

@font-face {
  font-family: helveticaFont;
  src: url("D:\Studies\others\react js\kamal_plastics\src\assets\font\helvetica.ttf");
}

.aboutimages,
.packingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topimage {
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.title {
  align-items: center;
  font-size: 40px;
  font-family: bellezaFont;
  @media only screen and (max-width: 768px) {
    align-items: center;
    font-size: 30px;
    font-family: bellezaFont;
  }
}
.long-brown-line {
  margin: 0 auto;
}
.lineimage {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
.block1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin: 10px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.left1 {
  width: 40%;
  background-color: #e7e2d9;
  border-radius: 10px;
  margin: 20px;
  padding: 15px;
  margin-left: 6%;
  height: max-contents;
  p {
    font-size: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
  }
}
.right1 {
  width: 40%;
  border-radius: 10px;
  margin: 20px;
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-bottom: 20px;
    margin-left: 10px;
  }
}
.aboutlogo {
  // object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.vblock1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 15px;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-content: baseline;
  }
}
.vleft1 {
  width: 50%;
  border-radius: 20px;
  padding-top: 5%;
  margin-left: 6%;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    padding-top: 0%;
    margin-bottom: 10px;
    align-self: center;
  }
}
.vright1 {
  width: 40%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 95%;
    margin: auto 0;
    // margin-left: 10px;
    align-self: center;
  }
}
.vtop {
  width: 85%;
  height: 40%;
  background-color: #e7e2d9;
  border-radius: 10px;
  padding: 15px;
  p {
    align-items: center;
    font-size: 19px;
    padding: 5px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
    p {
      font-size: 15px;
    }
  }
}
.vbottom {
  width: 85%;
  height: 40%;
  background-color: #e7e2d9;
  border-radius: 10px;
  padding: 15px;
  p {
    align-items: center;
    font-size: 19px;
    padding: 5px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
    p {
      font-size: 15px;
    }
  }
}
.vmiddle {
  margin: 10px;
}
.aboutowner {
  align-items: center;
  width: 80%;
  height: 80%;
  border-radius: 15px;
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
  }
}
.vtitle {
  padding: 5px;
  align-items: center;
  font-size: 30px;
  font-family: helveticaFont;
  font-weight: lighter;
  @media only screen and (max-width: 768px) {
    p {
      align-items: center;
      font-size: 25px;
      font-family: bellezaFont;
      font-weight: lighter;
    }
  }
}
.brandlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 150px;
  height: 100px;
  @media only screen and (max-width: 768px) {
    width: 100px;
    height: 70px;
  }
}
.rbrandlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20%;
  width: 150px;
  height: 100px;
  background-color: #e7e2d9;
  @media only screen and (max-width: 768px) {
    width: 150px;
    height: 70px;
  }
}
.bblock1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin: 10px;
  height: 100%;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
.bleft {
  width: 30%;
  background-color: #e7e2d9;
  border-radius: 40px;
  margin: 20px;
  padding: 15px;
  margin-left: 15%;
  p {
    padding: 10px;
    align-items: center;
    font-size: 19px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
    margin-bottom: 10px;
    p {
      font-size: 15px;
    }
  }
}
.bright {
  width: 30%;
  background-color: #e7e2d9;
  border-radius: 40px;
  margin: 20px;
  padding: 15px;
  margin-left: 5%;
  p {
    padding: 10px;
    align-items: center;
    font-size: 19px;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    margin: auto 0;
    margin-left: 10px;
    p {
      font-size: 15px;
    }
  }
}
.adv {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.advtitle {
  padding: 15px;
  align-items: center;
  font-size: 40px;
  font-family: Helvetica Now;
  @media only screen and (max-width: 768px) {
    align-items: center;
    font-size: 25px;
    font-family: serif;
  }
}
.whole {
  width: 80%;
  margin-left: 17%;
  margin-right: 10%;

  @media only screen and (max-width: 768px) {
    margin: auto 0;
    margin-left: 11%;
  }
}
.head {
  font-size: 30px;
  font-family: helviticaFont;
  text-align: left;
  @media only screen and (max-width: 768px) {
    p {
      font-size: 20px;
      font-family: helviticaFont;
      text-align: left;
    }
  }
}
.content {
  width: 80%;
  p {
    margin: 10px;
    font-size: 22px;
    text-align: left;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    p {
      font-size: 17px;
      text-align: left;
    }
  }
}
