.products-page-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .product-page-title {
    span {
      font-size: 2rem;
    }
  }

  .products-div {
    padding: 0 2vw;
  }
}
.underLine {
  height: 50px;
  width: 100%;
}
